import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageFiveService {
  static createStageFive (data) {
    return axios.post(`${url}/app/stage-five/create`, data)
  }
}

export default StageFiveService
